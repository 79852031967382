<template>
    <div class="card-detail">
        <van-cell-group :border="false">
            <van-cell title="课程套餐名称" :value="information.cardName" />
            <van-cell title="马场" :value="information.corpName" />
            <van-cell title="课程" :value="curriculumName" />
            <van-cell title="售价" :value="information.priceTotal" />
            <van-cell title="获得鞍时" :value="information.cardHours" />
        </van-cell-group>
        <div class="content">
            <div class="title">课程套餐描述</div>
            <div class="desc" v-html="information.detailsContent"></div>
        </div>
        
        <div class="tips">
            <div class="span"><van-icon name="photo-o" /><span>图片</span></div>
        </div>
        <div class="image-list">
            <van-uploader class="my-upload" v-model="fileList" multiple :deletable="false" :show-upload="false" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            information:{},
            curriculumName: '',
            fileList: []
        }
    },
    methods:{
        courseName(val) {
            let str = val
            let arr = []
            if(str.indexOf('*##*') !== -1){
                str.split(',').forEach(ele=>{
                    arr.push(ele.split('*##*')[1])
                })
                str = arr.join()
            }
            return str
        },
        getDetail() {
            this.$api.post('common/base/card/card-show', this.$route.query).then(res=>{
                // console.log(res)
                this.information = res
                let picList = []
                if(res.picUrl){
                    picList = res.picUrl.split(','); 
                    picList.forEach(ele=>{
                        this.fileList.push({url:this.getImg(ele), isImage: true })
                    })
                }
                this.curriculumName = this.courseName(this.information.curriculumName)
            })
        }
    },
    mounted() {
        this.getDetail()
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .card-detail{
        .tips{
            margin-top: 20px;
            .span{
                display: flex;
                align-items: center;
                background-color: $main;
                padding: 10px 20px;
                color: #fff;
                font-size: 14px;
                border-radius: 0 28px 28px 0;
                width: 60px;
            }
            .van-icon {
                padding-right: 5px;
                font-size: 18px;
            }
        }
        .content{
            .title{
                font-size: 14px;
                text-align: center;
            }
            margin: 15px;
            border-radius: 9px;
            box-shadow: 0 0 9px 6px $boxShadow;
            padding: 10px;
        }
        .image-list{
            padding: 20px 20px 0;
            background-color: #fff;
            margin-top: 20px;
            .my-upload {
                flex: 1;
                margin-bottom: 10px;
                .van-uploader__wrapper {
                    justify-content: flex-start;
                    
                    .van-uploader__preview{
                        margin-right: 8px;
                        margin-bottom: 8px;
                    }
                }
                .van-uploader__upload, .van-uploader__preview, .van-uploader__preview-image {
                    width: 100px;
                    margin: 0;
                    height: 100px;
                }

            }
        }
    }
</style>